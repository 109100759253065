// import * as React from "react";
import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { cn } from "../lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 flex gap-2",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-rose-600",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-primary text-primary bg-transparent hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "bg-transparent hover:bg-rose-800",
        link: "text-foreground underline-offset-4 hover:underline",
        transparent: "bg-transparent text-foreground hover:bg-foreground/10",
        white: "bg-white text-primary font-bold hover:bg-white/90",
        transparent_ghost:
          "bg-transparent text-foreground hover:bg-foreground/10",
        secondary_light:
          "bg-foreground/10 text-secondary-foreground hover:bg-foreground/10",
        default_glow:
          "bg-primary shadow shadow-primary text-primary-foreground hover:bg-rose-600 ring-2 ring-primary",
        pricing_button: "bg-primary text-primary-foreground hover:bg-rose-600",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        xl: "h-12 rounded-md px-8",
        xxl: "h-14 rounded-md px-7",
        icon: "h-10 w-10",
        iconMd: "h-9 w-9",
        iconSm: "h-8 w-8",
        iconXS: "h-7 w-7",
        iconXXL: "h-20 w-20 text-2xl",
        xs: "h-8 text-sm rounded-md px-3",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
