import React from "react";
import { Route, Routes } from "react-router-dom";
import TPL01 from "./Views/TPL01";
import TPL03 from "./Views/TPL03";
import HentaiGirlfriend from "./Views/Hentai";
import TPL02 from "./Views/TPL02";
import { useQuery } from "@tanstack/react-query";
import StrapiUpdatePage from "./Views/Test";
import JsonStrip from "./Views/Test/json-strip";
import TPL04 from "./Views/TPL04";
import TPL07 from "./Views/TPL07";
import TPL08 from "./Views/TPL08";

export default function Main() {
  const { isLoading: tokenLoading, data: allRedirect } = useQuery({
    queryKey: ["redirect-url"],
    queryFn: () => fetchData(),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  if (tokenLoading) return <div className="h-full"></div>;

  return (
    <div>
      <Routes>
        <Route
          path="/"
          index
          element={<HentaiGirlfriend allRedirect={allRedirect} />}
        />
        <Route path="/tpl01" index element={<TPL01 />} />
        <Route path="/tpl02" index element={<TPL02 />} />
        <Route path="/tpl03" index element={<TPL03 />} />
        <Route path="/tpl04" index element={<TPL04 />} />
        <Route path="/tpl05" index element={<TPL04 />} />
        <Route path="/tpl06" index element={<TPL04 />} />
        <Route path="/tpl07" index element={<TPL07 />} />
        <Route path="/tpl08" index element={<TPL08 />} />

        <Route path="/test" index element={<StrapiUpdatePage />} />
        <Route path="/json" index element={<JsonStrip />} />
      </Routes>
    </div>
  );
}

const fetchData = async () => {
  const apiToken = process.env.REACT_APP_STRAPI_ACCESS_TOKEN_SECRET;
  const strapiUrl = process.env.REACT_APP_STRAPI_URL;

  try {
    const response = await fetch(`${strapiUrl}/api/lander-1?`, {
      headers: {
        Authorization: `Bearer ${apiToken}`, // Ensure your API token is correctly set
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Network response was not ok: ${errorData.error.message}`
      );
    }

    const data = await response.json();
    return data.data.tplo1_redirect;
  } catch (error) {
    console.error("Fetch error:", error);
    // You might want to handle the error differently depending on your use case
    return null;
  }
};
