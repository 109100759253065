import root from "react-shadow";
// eslint-disable-next-line import/no-webpack-loader-syntax
import { useEffect, useState } from "react";
import { strapiUrl } from "../../lib/utils";
import { landerSettings } from "../../LanderSettings";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { Oval } from "react-loader-spinner";
import { Helmet } from "react-helmet";
import Steps from "./Templates/step";
import { FaPen } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function TPL08() {
  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3001/";
    window.lander = {
      version: "1.0",
      baseUrl: baseUrl,
      handler: (data) => {
        console.log(data);
      },
      settings: landerSettings,
    };
  }, []);

  const { isLoading: tokenLoading, data: cmsData } = useQuery({
    queryKey: ["cmsData"],
    queryFn: () => fetchData(),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const [selectedStyle, setSelectedStyle] = useState("Anime");

  const [referralCode, setReferralCode] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const changeStyle = (style) => {
    setSelectedStyle(style);
  };

  useEffect(() => {
    if (!cmsData) return;

    // Construct the base redirect URL from CMS data
    const baseRedirectUrl = cmsData.data.final_button.href;

    // Function to extract the referral code and construct the final redirect URL
    const getReferralCodeAndSetRedirectUrl = () => {
      // Check if running in a browser environment
      if (typeof window !== "undefined") {
        const params = new URLSearchParams(window.location.search);
        const ref = params.get("ref");

        // Check if baseRedirectUrl already contains query parameters
        const hasQuery = baseRedirectUrl.includes("?");
        let redirect = `${baseRedirectUrl}${
          hasQuery ? "&" : "?"
        }style=${selectedStyle}`; // Add style as a query parameter

        // Add referral code if it exists
        if (ref) {
          setReferralCode(ref);
          redirect += `&ref=${ref}`;
        }

        setRedirectUrl(redirect);
      }
    };

    getReferralCodeAndSetRedirectUrl();
  }, [cmsData, selectedStyle]); // Dependencies include cmsData and selectedStyle

  if (tokenLoading)
    return (
      <root.div className="flex h-screen justify-center items-center">
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href="./haven-ico.png"
            sizes="16x16"
          />
        </Helmet>

        <div className="font-poppins bg-black-default w-full h-full min-h-screen main-wrapper flex justify-center items-center">
          <Oval
            visible={true}
            height="50"
            width="50"
            color="#cc1a1a"
            secondaryColor="#630000"
            ariaLabel="tail-spin-loading"
            radius="2"
          />
        </div>
      </root.div>
    );

  return (
    <div className="h-full">
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          href="./haven-ico.png"
          sizes="16x16"
        />
      </Helmet>

      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap"
        rel="stylesheet"
      />

      {/* <div className="flex justify-center items-center mt-10">
        <div className="!aspect-[1/1] p-2 h-24 flex justify-center rounded-full bg-white/10">
          <img
            src="https://ph-ai-cdn.b-cdn.net/HH-Landing-IMGS/Logo.png"
            alt="HentaiHaven"
            className="h-20"
          />
        </div>
      </div> */}

      <div className="mx-auto max-w-6xl px-6 py-20 pt-16">
        <p className="flex justify-center gap-4 text-center text-3xl font-bold">
          <FaPen className="my-auto text-2xl text-white/40" />
          Create my AI
        </p>
        <div className="flex items-center justify-center py-14 pb-7">
          <Steps />
        </div>
        <div className="relative">
          <div className="absolute -top-0 -z-10 flex h-40 w-full items-center justify-center gap-1">
            <div className="flex size-[500px] items-center justify-center rounded-full bg-[#cc1a1a]/20 blur-[100px]" />
          </div>
          <div className="relative mx-auto max-w-3xl rounded-xl border border-white/10 bg-background p-4 py-6">
            <div className="sm:p-4">
              <div className="gap-3 flex justify-center">
                <h2 className="text-center text-xl font-semibold my-auto">
                  Choose Style*
                </h2>

                <div className="!aspect-[1/1] p-2 h-14 flex justify-center rounded-full bg-white/10">
                  <img
                    src="https://ph-ai-cdn.b-cdn.net/HH-Landing-IMGS/Logo.png"
                    alt="HentaiHaven"
                    className="h-10"
                  />
                </div>
              </div>

              <div className="mt-6 flex justify-between gap-2 sm:gap-5">
                {cmsData.data.style.map((trait) => (
                  <div
                    className="w-full p-3 duration-200 transition-all"
                    key={trait.id}
                    onClick={() => {
                      changeStyle(trait.text);
                    }}
                  >
                    <div
                      className={`relative h-full duration-200 transition-all overflow-hidden rounded-xl ${
                        selectedStyle === trait.text
                          ? "ring-4 ring-[#cc1a1a]"
                          : ""
                      }`}
                    >
                      <img
                        src={`${strapiUrl}${trait.image.url}`}
                        alt={trait.text}
                        className="h-full w-full object-cover rounded-xl"
                      />
                      <div className="absolute bottom-3 mx-auto w-full">
                        <div
                          className={`${
                            selectedStyle === trait.text
                              ? "bg-[#cc1a1a]"
                              : "bg-white/40"
                          } mx-auto w-fit rounded-full px-3 py-1 font-semibold`}
                        >
                          {trait.text}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-2 flex justify-end mt-6">
                <Link
                  className={`w-72 text-center lg:w-60 px-5 py-2.5 text-white font-semibold rounded-[10px] bg-gradient-to-r from-[#630000] to-[#cc1a1a] `}
                  to={redirectUrl}
                >
                  Next
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const fetchData = async () => {
  const apiToken = process.env.REACT_APP_STRAPI_ACCESS_TOKEN_SECRET;
  const strapiUrl = process.env.REACT_APP_STRAPI_URL;

  // Construct the query string using qs
  const query = qs.stringify(
    {
      populate: {
        style: {
          populate: ["image"],
        },
        final_button: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  try {
    const response = await fetch(`${strapiUrl}/api/lander-8?${query}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`, // Ensure your API token is correctly set
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Network response was not ok: ${errorData.error.message}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    // You might want to handle the error differently depending on your use case
    return null;
  }
};
