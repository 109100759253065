import { CheckIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Steps() {
  const currentStep = 1;
  const steps = [
    {
      name: "Step 1",
      status:
        currentStep > 1
          ? "complete"
          : currentStep === 1
          ? "current"
          : "upcoming",
    },
    {
      name: "Step 2",
      status:
        currentStep > 2
          ? "complete"
          : currentStep === 2
          ? "current"
          : "upcoming",
    },
    {
      name: "Step 3",
      status:
        currentStep > 3
          ? "complete"
          : currentStep === 3
          ? "current"
          : "upcoming",
    },
    {
      name: "Step 4",
      status:
        currentStep > 4
          ? "complete"
          : currentStep === 4
          ? "current"
          : "upcoming",
    },
    {
      name: "Step 5",
      status:
        currentStep > 5
          ? "complete"
          : currentStep === 5
          ? "current"
          : "upcoming",
    },
    {
      name: "Step 6",
      status:
        currentStep > 6
          ? "complete"
          : currentStep === 6
          ? "current"
          : "upcoming",
    },
    {
      name: "Step 7",
      status:
        currentStep > 7
          ? "complete"
          : currentStep === 7
          ? "current"
          : "upcoming",
    },
    {
      name: "Step 8",
      status:
        currentStep > 8
          ? "complete"
          : currentStep === 8
          ? "current"
          : "upcoming",
    },
    {
      name: "Step 9",
      status:
        currentStep > 9
          ? "complete"
          : currentStep === 9
          ? "current"
          : "upcoming",
    },
  ];

  return (
    <nav aria-label="Progress">
      <ol role="list" className="m flex items-center">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pr-6 sm:pr-[62px]" : "",
              "relative"
            )}
          >
            {step.status === "complete" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-primary" />
                </div>
                <p className="hover:bg-pimary/50 relative flex h-5 w-5 items-center justify-center rounded-full bg-primary p-[3px]">
                  <CheckIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </p>
              </>
            ) : step.status === "current" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-foreground/50" />
                </div>
                <p
                  className="relative flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-white"
                  aria-current="step"
                >
                  <span className="sr-only">{step.name}</span>
                </p>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-white/60" />
                </div>
                <p className="group relative flex h-5 w-5 items-center justify-center rounded-full border-2 border-foreground/50 bg-zinc-600 hover:border-gray-400">
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-foreground/50"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </p>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
